<template>
  <v-fade-transition>
    <v-row
      :class="`ma-0 px-3 mb-4 py-0 ${
        isCustomer ? 'justify-start' : 'justify-center '
      }`"
      align="center"
      dense
      v-if="hasAppliedFilters"
    >
      <TooltipIcon :icon="'mdi-filter-variant'" label="Filtrando por..." />

      <v-scroll-x-transition
        v-for="filter in updatedFilters"
        :key="`${filter.value}-${filter.search}`"
      >
        <v-chip
          class="ml-2 my-2 text-truncate pr-2"
          style="max-width: 300px; outline: 1px solid #d4d6da"
          color="white"
          close-label="Remover filtro"
          close
          @click:close="remove(filter)"
        >
          <span class="font-weight-medium mr-1">{{ filter.text }}:</span>
          <span class="text-truncate"> {{ getValue(filter) }}</span>
        </v-chip>
      </v-scroll-x-transition>
    </v-row>
  </v-fade-transition>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { endTrialPeriodOptions } from "@/helpers/variables/dates";
import { subscriptionsList } from "@/helpers/variables/subscriptionPlans";
import { statusList } from "@/helpers/variables/subscriptionPlans";
import { status } from "@/helpers/variables/translateString";

export default {
  name: "FilteringByRow",

  props: {
    currentFilters: { type: Array, default: () => [] },
    isUser: { type: Boolean, default: false },
    isCustomer: { type: Boolean, default: false },
  },

  data() {
    return {
      localFilters: [],
    };
  },

  computed: {
    ...mapGetters([
      "primaryColor",
      "secondaryColor",
      "allVacationTemplates",
      "allTemplates",
      "plans",
      "resellersList",
      "allOrgUnits",
      "resellers",
    ]),

    doNotShowThisItens() {
      return ["name", "email", "user.name", "user.email", "main_domain"];
    },

    hasAppliedFilters() {
      return this.currentFilters.length > 0;
    },

    updatedFilters() {
      return this.currentFilters.filter(
        (item) =>
          item.search !== "" &&
          item.search !== null &&
          !this.doNotShowThisItens.includes(item.value)
      );
    },

    filtersName() {
      return [{}, ...this.filterOptions].reduce((total, next) => {
        total[next.value] = next.text;
        return total;
      });
    },

    filters() {
      return this.filterOptions.filter(
        (option) =>
          !(
            (option.type != "text" && this.search) ||
            this.currentFilters.find((filter) => filter.value == option.value)
          )
      );
    },

    activePlans() {
      return this.plans.filter((item) => item.is_active && item.is_visbile);
    },

    functionsList() {
      return [
        { value: true, text: "Administrador" },
        { value: false, text: "Membro" },
      ];
    },

    lists() {
      return {
        is_google_admin: this.functionsList,
        applied_signature_key: this.formatFilterList(this.allTemplates),
        applied_vacation_key: this.formatFilterList(this.allVacationTemplates),
        "current_plan.*.plan.name": this.formatFilterList(this.activePlans),
        "current_plan.*.end_trial": this.formatFilterList(
          endTrialPeriodOptions
        ),
        "current_plan.*.subscription_type":
          this.formatFilterList(subscriptionsList),
        "current_plan.*.status": this.formatFilterList(statusList),
        "reseller_company.name": this.formatFilterList(this.resellersList),
      };
    },
  },

  watch: {
    localFilters() {
      this.$emit("update", this.localFilters);
    },
  },

  methods: {
    ...mapActions(["getResellers", "getPlans"]),

    getValue(filter) {
      const key = filter.value;
      const { search } = filter;

      if (key === "ou" && search === "/") {
        return "/principal";
      } else if (filter.type === "bool") {
        return status[key.toUpperCase()];
      }

      const isInsideSomeList = this.lists[key]
        ? this.lists[key].find((item) => item.value == search)
        : false;

      if (isInsideSomeList) {
        return isInsideSomeList.text;
      }

      return filter.search;
    },

    remove(filter) {
      this.$emit("remove:filter", filter);
    },

    formatFilterList(list) {
      return list.reduce((acc, item) => {
        acc.push({
          value: item.key || item.id || item.value,
          text: item.name || item.text,
        });
        return acc;
      }, []);
    },
  },

  mounted() {
    this.localFilters = this.currentFilters;
  },

  beforeMount() {
    if (this.isCustomer) {
      if (!this.resellers || this.resellers.length === 0) {
        this.getResellers();
      }

      if (!this.plans || this.plans.length === 0) {
        this.getPlans();
      }
    }
  },
};
</script>
