<template>
  <div>
    <v-card-subtitle class="font-weight-medium pt-0">
      Status do cliente
    </v-card-subtitle>
    <v-card-text>
      <v-autocomplete
        v-model="selecteds"
        :items="clientStatusList"
        hide-selected
        outlined
        item-text="name"
        item-value="value"
        hide-details
        clearable
        chips
        multiple
        height="10"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="remove(data.item.value)"
          >
            {{ data.item.name }}
          </v-chip>
        </template>
      </v-autocomplete>
    </v-card-text>
  </div>
</template>

<script>
import { clientStatusList } from "@/helpers/variables/subscriptionPlans";

export default {
  name: "ClientStatusPicker",

  props: {
    selected: { type: Array, default: () => [] },
  },

  data() {
    return {
      selecteds: [],
      clientStatusList,
    };
  },

  watch: {
    selecteds() {
      this.$emit("update", this.selecteds);
    },

    selected() {
      this.selecteds = this.selected;
      this.$emit("update", this.selecteds);
    },
  },

  methods: {
    init() {
      if (this.selected) {
        this.selecteds = this.selected;
      } else {
        this.selecteds = [];
      }
    },
    remove(item) {
      this.selecteds = this.selecteds.filter((status) => status != item);
    },
  },

  beforeMount() {
    this.init();
  },
};
</script>
