<template>
  <v-container style="max-width: 1160px">
    <v-form class="pb-6 overflow-x-hidden">
      <v-card-title v-if="$vuetify.breakpoint.mdAndUp" class="px-0 pt-0">
        {{ $t("vacationApply.absenseSettings.title") }}
      </v-card-title>
      <v-card-text
        v-if="$vuetify.breakpoint.mdAndUp"
        class="px-0 text-body-1 mb-4"
      >
        {{ $t("vacationApply.absenseSettings.subtitle") }}
      </v-card-text>

      <v-card outlined class="pb-2">
        <v-card-subtitle class="font-weight-medium">
          {{ $t("vacationApply.absenseSettings.calendarSettings") }}
        </v-card-subtitle>
        <v-divider />
        <v-row
          :align="$vuetify.breakpoint.smAndDown ? 'start' : 'center'"
          class="px-4 pt-2"
        >
          <v-col
            v-if="!$vuetify.breakpoint.xs"
            cols="12"
            sm="12"
            lg="3"
            class="text-body-1 font-weight-medium my-4"
          >
            {{ $t("vacationApply.absenseSettings.addHolidayEvent") }}
          </v-col>
          <v-col cols="12" sm="12" lg="9">
            <v-switch
              v-model="vacationSettings.create_calendar_event"
              :label="createScheduleEventLabel"
              color="accentSuite"
              style="max-height: 50px"
              inset
              hide-details
            />
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-row
            v-show="vacationSettings.create_calendar_event"
            :align="$vuetify.breakpoint.smAndDown ? 'start' : 'center'"
            :class="`px-4 py-2 ${
              !vacationSettings.create_calendar_event ? 'grey--text' : ''
            }`"
          >
            <v-col
              v-if="!$vuetify.breakpoint.xs"
              cols="12"
              sm="12"
              lg="3"
              class="text-body-1 font-weight-medium"
            >
              {{ $t("vacationApply.configure.eventTitle") }}
              <span class="error--text font-weight-medium">*</span>
            </v-col>
            <v-col cols="12" sm="12" lg="9">
              <v-text-field
                :disabled="!vacationSettings.create_calendar_event"
                append-icon="mdi-calendar-edit"
                v-model="vacationSettings.calendar_event_name"
                :label="$t('vacationApply.configure.selectEventName')"
                hide-details
                outlined
                color="accentSuite"
              />
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-card>
      <v-card outlined class="pb-2 mt-7">
        <v-card-subtitle class="font-weight-medium">
          {{ $t("vacationApply.configure.forwarding") }}
        </v-card-subtitle>
        <v-divider />
        <v-row
          :align="$vuetify.breakpoint.smAndDown ? 'start' : 'center'"
          class="px-4 pt-2"
        >
          <v-col
            v-if="!$vuetify.breakpoint.xs"
            cols="12"
            sm="12"
            lg="3"
            class="text-body-1 font-weight-medium my-4"
          >
            {{ $t("vacationApply.configure.forwardingToUser") }}
          </v-col>
          <v-col cols="12" sm="12" lg="9">
            <v-switch
              v-model="enable_forwarding_email_on_vacation"
              :label="fowardingEmailEventLabel"
              color="accentSuite"
              style="max-height: 50px"
              inset
              hide-details
            />
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-row
            v-show="enable_forwarding_email_on_vacation"
            :align="$vuetify.breakpoint.smAndDown ? 'start' : 'center'"
            :class="`px-4 py-2 ${
              !enable_forwarding_email_on_vacation ? 'grey--text' : ''
            }`"
          >
            <v-col
              v-if="!$vuetify.breakpoint.xs"
              cols="12"
              sm="12"
              lg="3"
              class="text-body-1 font-weight-medium mb-8"
            >
              {{ $t("vacationApply.configure.receiver") }}
              <span class="error--text font-weight-medium">*</span>
            </v-col>
            <v-col cols="12" sm="12" lg="9">
              <v-autocomplete
                :disabled="!enable_forwarding_email_on_vacation"
                v-model="vacationSettings.forwarding_email_on_vacation"
                :items="users"
                :label="$t('vacationApply.configure.selectReceiver')"
                outlined
                color="accentSuite"
                item-text="email"
                hide-selected
                clearable
              />
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-card>

      <v-card-actions
        :class="`${$vuetify.breakpoint.smAndDown ? 'mt-4' : ''} px-2`"
      >
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          :disabled="disableNext"
          @click="$emit('vacationSettings', updatedSettings)"
          large
          class="px-6"
          color="accent"
          :dark="!disableNext"
        >
          {{ $t("action.next") }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AbsenseSettings",

  props: {
    html: { type: String, required: true },
    subject: { type: String, required: true },
    name: { type: String, default: "" },
  },

  components: {},

  data() {
    return {
      vacationSettings: {
        create_calendar_event: false,
        calendar_event_name: this.$t("vacationApply.configure.eventName"),
        forwarding_email_on_vacation: false,
      },
      enable_forwarding_email_on_vacation: false,
    };
  },
  computed: {
    ...mapGetters(["mainDomain", "users"]),

    createCalendarEvent() {
      return (
        this.vacationSettings.create_calendar_event &&
        this.vacationSettings.calendar_event_name
      );
    },

    enableForwardingEmailOnVacation() {
      return (
        this.enable_forwarding_email_on_vacation &&
        this.vacationSettings.forwarding_email_on_vacation
      );
    },

    disableNext() {
      if (
        this.vacationSettings.create_calendar_event &&
        !this.vacationSettings.calendar_event_name
      ) {
        return true;
      }

      if (
        this.enable_forwarding_email_on_vacation &&
        !this.vacationSettings.forwarding_email_on_vacation
      ) {
        return true;
      }

      return false;
    },

    createScheduleEventLabel() {
      if (this.$vuetify.breakpoint.xs) {
        return this.$t("vacationApply.configure.addEvent");
      }
      return this.vacationSettings.create_calendar_event
        ? this.$t("common.activated")
        : this.$t("common.deactivated");
    },

    fowardingEmailEventLabel() {
      if (this.$vuetify.breakpoint.xs) {
        return this.$t("vacationApply.configure.forwarding");
      }
      return this.enable_forwarding_email_on_vacation
        ? this.$t("common.activated")
        : this.$t("common.deactivated");
    },

    updatedSettings: {
      get() {
        return this.vacationSettings;
      },
      set(new_value) {
        this.vacationSettings = new_value;
      },
    },
  },

  beforeDestroy() {
    this.vacationSettings = {
      create_calendar_event: false,
      calendar_event_name: this.$t("vacationApply.configure.eventName"),
      forwarding_email_on_vacation: false,
    };
    this.enable_forwarding_email_on_vacation = false;
    this.$forceUpdate();
  },
};
</script>
