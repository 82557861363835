<template>
  <div>
    <v-card-subtitle class="font-weight-medium pt-0">
      Plano de pagamento
    </v-card-subtitle>
    <v-card-text>
      <v-autocomplete
        v-model="selectedSubscripionType"
        :items="subscriptionsList"
        hide-selected
        outlined
        item-text="name"
        item-value="value"
        clearable
        hide-details
      />
    </v-card-text>
  </div>
</template>

<script>
import { subscriptionsList } from "@/helpers/variables/subscriptionPlans";
import { mapGetters } from "vuex";

export default {
  name: "SubscriptionTypePicker",

  props: {
    selected: { type: [Array, String], default: "" },
  },

  data() {
    return {
      selectedSubscripionType: "",

      subscriptionsList,
    };
  },

  computed: {
    ...mapGetters(["secondaryColor", "primaryColor"]),
  },

  watch: {
    selectedSubscripionType() {
      this.$emit("update", this.selectedSubscripionType);
    },

    selected() {
      if (!this.selected) {
        this.selectedSubscripionType = this.selected;
      }
    },
  },

  methods: {
    init() {
      if (this.selected) {
        this.selectedSubscripionType = this.selected;
      } else {
        this.selectedSubscripionType = "";
      }
    },
  },

  beforeMount() {
    this.init();
  },
};
</script>
