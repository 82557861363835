<template>
  <HeaderDialog
    v-if="showHideDialog"
    id="apply_sendas_batch"
    color="primary"
    :title="$t('common.applySecondaryEmail')"
    :show="showHideDialog"
    :persistent="false"
    :loading="loadingSign || loadingSendAs || loadingEmails || loading"
    width="700"
    @close="showHideDialog = false"
    @action="selectSendAsMethod"
    :disabledAction="
      sendAsEmailIsValid ||
      !sendAsEmail.length ||
      loadingSign ||
      loadingSendAs ||
      loading
    "
    :closeText="$t('action.cancel')"
    button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
  >
    <template v-slot:body>
      <p class="pt-4 pb-2">
        {{ $t("common.secondaryEmailAddressDescription") }}
      </p>

      <v-combobox
        v-model="setSendAsEmail"
        :rules="[(v) => validateEmail(v, $t('textFieldRules.invalidEmail'))]"
        class="py-0 mt-2"
        color="primary"
        label="E-mail"
        :hint="`${$t(
          'common.addAlternativeEmailDescription'
        )} Ex.: comercial@, contato@, financeiro@…`"
        clearable
        open-on-clear
        :items="emails"
        dense
        :loading="loadingEmails"
        :disabled="loadingSign || loadingSendAs || loading"
      />

      <v-text-field
        v-model="sendAsName"
        class="py-0 mt-6"
        :label="`${$t('common.name')} (${$t('common.optional')})`"
        color="primary"
        :disabled="loadingSign || loadingSendAs || loading"
      ></v-text-field>
    </template>
  </HeaderDialog>
</template>
<script>
import { validateEmail } from "@/helpers/services/utils";
import { mapGetters, mapActions } from "vuex";
import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";

export default {
  name: "ApplySendAs",

  props: {
    show: { type: Boolean, required: true },
    users: { type: Array },
    loading: { type: Boolean, default: () => false },
  },

  data() {
    return {
      sendAsName: "",
      sendAsEmail: "",
      sendAsEmailIsValid: false,
      groupScope: INCREMENTAL_SCOPES.ADMIN_DIRECTORY_GROUP,
      loadingSendAs: false,
      loadingEmails: false,
      emails: [],
    };
  },

  computed: {
    ...mapGetters([
      "loadingSign",
      "scopeCode",
      "userSendAs",
      "userGroups",
      "groupEmails",
    ]),
    ...mapGetters({ allUsers: "users" }),

    setSendAsEmail: {
      get() {
        return this.sendAsEmail;
      },
      set(email) {
        this.sendAsEmail = email;
        this.sendAsEmailIsValid =
          validateEmail(email, this.$t("textFieldRules.invalidEmail")) !== true;
      },
    },

    showHideDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },

    groupScopeCode() {
      return this.scopeCode(this.groupScope);
    },

    singleUser() {
      return this.users.length == 1;
    },

    usersEmails() {
      return (this.allUsers || []).map((user) => user.email);
    },
  },

  watch: {
    show() {
      if (this.show) {
        this.sendAsName = "";
        this.sendAsEmail = "";
        this.loadEmails();
      }
    },
  },

  methods: {
    validateEmail,

    ...mapActions([
      "applySendAs",
      "createUserSendAs",
      "checkScopes",
      "getGoogleWorkspaceGroups",
      "getGroupsByUser",
      "getSendAsByUser",
    ]),

    async applySendAsToUsers() {
      const payload = {
        sendAsName: this.sendAsName,
        users: this.users,
        sendAsEmail: this.sendAsEmail,
      };

      await this.applySendAs(payload).finally(() => {
        this.$emit("close");
        this.$emit("loading", false);
        this.loadingSendAs = false;
        this.sendAsEmailIsValid = false;
      });
    },

    async addUserSendAs() {
      const payload = {
        email: this.sendAsEmail,
        name: this.sendAsName,
        id_user: this.users[0].id_google,
      };

      await this.createUserSendAs(payload)
        .then((data) => {
          this.$emit("then", data);
        })
        .finally(() => {
          this.$emit("close");
          this.$emit("loading", false);
          this.loadingSendAs = false;
        });
    },

    selectSendAsMethod() {
      this.loadingSendAs = true;
      this.$emit("loading", true);
      if (this.singleUser) {
        this.addUserSendAs();
      } else {
        this.applySendAsToUsers();
      }
    },

    async loadEmails() {
      this.loadingEmails = true;
      this.emails = this.usersEmails.slice();
      let groupScopeCode = "";
      if (!this.groupScopeCode) {
        const response = await this.checkScopes({
          scopes: [this.groupScope],
        });
        groupScopeCode = response.data.code;
      }
      if ((this.groupScopeCode || groupScopeCode) !== "ACCEPTED_SCOPES") return;
      if (this.singleUser) {
        const user = this.users[0];
        if (!this.userGroups(user.id_google)) await this.getGroupsByUser(user);
        if (!this.userSendAs(user.id_google)) await this.getSendAsByUser(user);
      } else {
        await this.getGoogleWorkspaceGroups();
      }
      this.resetEmails();
      this.loadingEmails = false;
    },

    resetEmails() {
      const groupScopeAccepted = this.groupScopeCode === "ACCEPTED_SCOPES";
      let groups = [];
      let sendAs = [];

      if (this.singleUser) {
        const user = this.users[0];
        const id_google = user.id_google;
        if (groupScopeAccepted) {
          groups = this.userGroups(id_google) || [];
        }
        sendAs = (this.userSendAs(id_google) || []).map((item) => item.email);
        this.emails = this.usersEmails
          .concat(groups)
          .filter((email) => email != user.email && !(email in sendAs));
      } else {
        if (groupScopeAccepted) {
          groups = this.groupEmails || [];
          this.emails = this.usersEmails.concat(groups);
        }
      }
    },
  },
};
</script>
