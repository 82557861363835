<template>
  <div class="mb-4">
    <v-card-subtitle class="font-weight-medium pt-0">
      {{ $t("filters.signatureModelApplied") }}
      <TooltipIcon
        icon="mdi-information-slab-circle-outline"
        color="secondary lighten-3"
        :label="$t('filters.signatureModelAppliedDescription')"
      />
    </v-card-subtitle>
    <v-card-text>
      <v-autocomplete
        v-model="applied_signature_key"
        :items="allTemplates"
        :multiple="!singleChoice"
        :hide-selected="singleChoice"
        outlined
        :small-chips="singleChoice"
        item-text="name"
        item-value="id"
        hide-details
        clearable
        @input="update"
      >
        <!-- ITENS SELECIONADOS -->
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <span v-if="singleChoice">{{ item.name }}</span>
          <v-chip
            v-else
            v-bind="attrs"
            :color="secondaryColor"
            :input-value="selected"
            label
            small
            dark
            :placeholder="$t('filters.noSignatureModelSelected')"
          >
            <span class="pr-2">
              {{ item.name }}
            </span>
            <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
          </v-chip>
        </template>

        <!-- <template v-slot:prepend-item> Limpar seleção

        </template> -->

        <!-- ITENS DISPONÍVEIS PARA SELEÇÃO -->
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item
            v-on="on"
            v-bind="attrs"
            :active-class="lightColors[productName]"
            :class="`mb-1`"
            max-width="200"
          >
            <v-list-item-icon v-if="!singleChoice">
              <v-icon
                :color="isSelected(item.id) ? `${primaryColor} darken-2` : ''"
                v-text="
                  isSelected(item.id)
                    ? 'mdi-checkbox-marked'
                    : 'mdi-checkbox-blank-outline'
                "
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.name"
                class="text-left font-weight-medium"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-if="item.description"
                v-text="item.description"
                class="text-left"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-card-text>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { lightColors } from "@/helpers/variables/colors";

export default {
  name: "SignatureTemplatePicker",

  props: {
    selected: { type: [Array, String], default: () => "" },
    returnIds: { type: Boolean, default: false },
    singleChoice: { type: Boolean, default: false },
  },

  data() {
    return {
      applied_signature_key: [],
      lightColors,
    };
  },

  computed: {
    ...mapGetters([
      "secondaryColor",
      "primaryColor",
      "allTemplates",
      "productName",
    ]),

    selectedIds() {
      return this.singleChoice
        ? this.applied_signature_key
        : this.applied_signature_key.map((item) => item.id);
    },
  },

  watch: {
    applied_signature_key() {
      this.update();
    },

    selected() {
      if (!this.selected) {
        this.applied_signature_key = this.selected;
      }
    },
  },

  methods: {
    ...mapActions(["getAllSignatureTemplates"]),

    isSelected(id) {
      if (this.singleChoice) {
        return id === this.applied_signature_key;
      } else {
        return this.applied_signature_key.find((item) => item.id === id);
      }
    },

    reset() {
      if (
        typeof this.selected !== "string" &&
        this.selected &&
        this.selected.length > 0
      ) {
        if (typeof this.selected[0] === "string") {
          this.applied_signature_key = this.selected.reduce((acc, item) => {
            const search = this.allTemplates.find(
              (template) => template.id === item
            );

            if (search) {
              acc.push(search);
            }

            return acc;
          }, []);
        } else {
          this.applied_signature_key = this.selected;
        }
      } else {
        this.applied_signature_key = this.selected || "";
      }
    },

    update() {
      if (this.returnIds) {
        this.$emit("update", this.selectedIds);
      } else {
        this.$emit("update", this.applied_signature_key);
      }
    },
  },

  beforeMount() {
    if (this.allTemplates.length === 0) {
      this.getAllSignatureTemplates();
    }

    this.reset();
  },
};
</script>
