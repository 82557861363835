<template>
  <div class="mb-4">
    <v-card-subtitle class="font-weight-medium pt-0 text--lighten-2">
      {{ $t("filters.vacationMessageTemplateApplied") }}
      <TooltipIcon
        icon="mdi-information-slab-circle-outline"
        color="secondary lighten-3"
        :label="$t('filters.vacationMessageTemplateAppliedDescription')"
      />
    </v-card-subtitle>
    <v-card-text>
      <v-autocomplete
        v-model="applied_vacation_key"
        :items="allVacationTemplates"
        :multiple="!singleChoice"
        :hide-selected="singleChoice"
        outlined
        small-chips
        item-text="name"
        item-value="key"
        hide-details
        clearable
        @input="update"
      >
        <!-- ITENS SELECIONADOS -->
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <span v-if="singleChoice">{{ item.name }}</span>
          <v-chip
            v-else
            v-bind="attrs"
            :color="secondaryColor"
            :input-value="selected"
            label
            small
            dark
            placeholder="Nenhum modelo de assinatura selecionado"
          >
            <span class="pr-2">
              {{ item.name }}
            </span>
            <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
          </v-chip>
        </template>

        <!-- ITENS DISPONÍVEIS PARA SELEÇÃO -->
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item
            v-on="on"
            v-bind="attrs"
            :active-class="lightColors[productName]"
            :class="`mb-1`"
            max-width="200"
          >
            <v-list-item-icon v-if="!singleChoice">
              <v-icon
                :color="isSelected(item.key) ? `${primaryColor} darken-2` : ''"
                v-text="
                  isSelected(item.key)
                    ? 'mdi-checkbox-marked'
                    : 'mdi-checkbox-blank-outline'
                "
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.name"
                class="text-left font-weight-medium"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-if="item.description"
                v-text="item.description"
                class="text-left"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-card-text>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { lightColors } from "@/helpers/variables/colors";

export default {
  name: "VacationTemplatePicker",

  props: {
    selected: { type: [Array, String], default: () => "" },
    tooltipIcon: {
      type: String,
      default: "mdi-information-slab-circle-outline",
    },
    tooltipLabel: { type: String, default: "" },
    returnIds: { type: Boolean, default: false },
    singleChoice: { type: Boolean, default: false },
  },

  data() {
    return {
      applied_vacation_key: [],
      lightColors,
    };
  },

  computed: {
    ...mapGetters([
      "secondaryColor",
      "primaryColor",
      "allVacationTemplates",
      "productName",
    ]),

    selectedIds() {
      return this.singleChoice
        ? this.applied_vacation_key
        : this.applied_vacation_key.map((item) => item.key);
    },
  },

  watch: {
    applied_vacation_key() {
      this.update();
    },

    selected() {
      if (!this.selected) {
        this.applied_vacation_key = this.selected;
      }
    },
  },

  methods: {
    ...mapActions(["getVacationTemplates"]),

    isSelected(key) {
      if (this.singleChoice) {
        return key === this.applied_vacation_key;
      } else {
        return this.applied_vacation_key.find((item) => item.key === key);
      }
    },

    reset() {
      if (
        typeof this.selected !== "string" &&
        this.selected &&
        this.selected.length > 0
      ) {
        if (typeof this.selected[0] === "string") {
          this.applied_vacation_key = this.selected.reduce((acc, item) => {
            const search = this.allVacationTemplates.find(
              (template) => template.key === item
            );

            if (search) {
              acc.push(search);
            }

            return acc;
          }, []);
        } else {
          this.applied_vacation_key = this.selected;
        }
      } else {
        this.applied_vacation_key = this.selected || "";
      }
    },

    update() {
      if (this.returnIds) {
        this.$emit("update", this.selectedIds);
      } else {
        this.$emit("update", this.applied_vacation_key);
      }
    },
  },

  beforeMount() {
    if (this.allVacationTemplates.length === 0) {
      this.getVacationTemplates();
    }

    this.reset();
  },
};
</script>
