<template>
  <div>
    <v-card-subtitle class="font-weight-medium pt-0">
      Parceiro atual
      <TooltipIcon
        icon="mdi-information-slab-circle-outline"
        color="secondary lighten-3"
        label="Empresa parceiras que revendem o Conecta Suite"
      />
    </v-card-subtitle>
    <v-card-text>
      <v-autocomplete
        v-model="selectedPartner"
        :key="selected"
        :items="resellersList"
        hide-selected
        outlined
        item-text="name"
        item-value="value"
        hide-details
        clearable
      >
        <!-- ITENS DISPONÍVEIS PARA SELEÇÃO -->
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title
              v-text="item.name"
              class="text-left font-weight-medium"
            ></v-list-item-title>
            <v-list-item-subtitle
              v-text="item.domain"
              class="text-left"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-card-text>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EndTrialPicker",

  props: {
    selected: { type: [Array, String], default: "" },
  },

  data() {
    return {
      selectedPartner: "",
    };
  },

  computed: {
    ...mapGetters(["resellersList", "resellers"]),
  },

  watch: {
    selectedPartner() {
      this.$emit("update", this.selectedPartner);
    },

    selected() {
      if (!this.selected) {
        this.selectedPartner = this.selected;
      }
    },
  },

  methods: {
    ...mapActions(["getResellers"]),

    init() {
      if (this.selected) {
        this.selectedPartner = this.selected;
      } else {
        this.selectedPartner = "";
      }
    },
  },

  beforeMount() {
    if (this.resellers.length === 0) {
      this.getResellers();
    }

    this.init();
  },
};
</script>
