<template>
  <v-data-table
    :value="currentSelected"
    @input="$emit('currentSelectedChange', $event)"
    :headers="headers"
    :items="allUsers"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    :item-key="itemKey"
    :items-per-page="itemsPerPage"
    :footer-props="{
      itemsPerPageOptions: itemsOptions,
    }"
    :options="pagination"
    @update:options="$emit('paginationChange', $event)"
    :server-items-length="totalUsers"
    no-results-text="Não foram encontrados resultados para a sua pesquisa"
    :loading="loading"
    loading-text="Carregando lista de usuários..."
    class="rounded-lg"
    :height="getNewUsersTableHeigth"
    :fixed-header="$vuetify.breakpoint.mdAndUp"
    :show-select="showSelect"
    :single-select="false"
    checkbox-color="blue"
    @toggle-select-all="getPageSelection"
  >
    <template v-slot:top>
      <slot name="top"></slot>
    </template>

    <template v-for="h in headers" v-slot:[`header.`+h.value]="{ props }">
      <slot :name="'header.' + h.value" v-bind="{ header: h, props }">{{
        h.text
      }}</slot>
    </template>

    <template v-slot:[`item.is_google_admin`]="{ item }">
      {{ item.is_google_admin ? "Administrador" : "Membro" }}
    </template>

    <template v-slot:[`item.is_suspended`]="{ item }">
      {{ item.is_suspended ? "Suspenso" : "Ativo" }}
    </template>
    <!-- INÍCIO DO CORPO DA TABELA -->
    <template v-for="h in headers" v-slot:[`item.`+h.value]="{ item }">
      <slot :name="'item.' + h.value" v-bind="{ item }">{{
        item[h.value]
      }}</slot>
    </template>
  </v-data-table>
</template>

<script>
import { checkIfNeverLoggedIn } from "@/helpers/services/users";
import { formatDateAndHour } from "@/helpers/services/utils";
import { paging } from "@/helpers/variables/tablesDefault";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "UsersTable",
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    users: {
      type: Array,
      default: () => [],
    },
    totalUsers: {
      type: Number,
      default: () => 0,
    },
    itemKey: {
      type: String,
      default: () => "id",
    },
    headers: {
      type: Array,
      default: () => [
        { text: "Usuários", align: "start", value: "name", sortable: true },
        { text: "E-mail", width: 280, value: "email", sortable: true },
        { text: "Departamento", value: "departament", sortable: true },
        {
          text: "Último login",
          width: 200,
          value: "last_login",
          sortable: false,
        },
      ],
    },
    pagination: {
      type: Object,
      default: () => ({
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: paging.perPage,
      }),
    },
    currentSelected: {
      type: Array,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      itemsOptions: paging.All,
    };
  },

  computed: {
    ...mapGetters(["getNewUsersTableHeigth", "allEntitiesFilteredToApply"]),

    allUsers() {
      return this.users;
    },
    sortBy: {
      get() {
        return this.pagination.sortBy;
      },
      set(newValue) {
        this.$emit("paginationChange", {
          ...this.pagination,
          sortBy: newValue,
        });
      },
    },
    sortDesc: {
      get() {
        return this.pagination.sortDesc;
      },
      set(newValue) {
        this.$emit("paginationChange", {
          ...this.pagination,
          sortDesc: newValue,
        });
      },
    },
    itemsPerPage: {
      get() {
        return this.pagination.itemsPerPage;
      },
      set(newValue) {
        this.$emit("paginationChange", {
          ...this.pagination,
          itemsPerPage: newValue,
        });
      },
    },
  },

  watch: {
    users() {
      this.$forceUpdate();
    },
  },

  methods: {
    ...mapMutations(["setAllEntitiesFilteredToApply"]),

    formatDateAndHour,

    checkIfNeverLoggedIn,

    getPageSelection(event) {
      this.$emit("toggleSelectAll", event);
    },
    handleSelect({ item, value }) {
      if (!this.allEntitiesFilteredToApply.length) {
        return;
      }

      let allEntitiesFilteredToApply = [...this.allEntitiesFilteredToApply];

      if (value) {
        allEntitiesFilteredToApply.push(item);
      } else {
        allEntitiesFilteredToApply = allEntitiesFilteredToApply.filter(
          (entity) => {
            if (entity.id_google && entity.id_google !== item.id_google) {
              return entity;
            } else if (entity.id !== item.id) {
              return entity;
            }
          }
        );
      }

      this.setAllEntitiesFilteredToApply(allEntitiesFilteredToApply);
    },
  },
};
</script>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-right: 0;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2) {
  padding-left: 3px;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(2) {
  padding-left: 3px;
}
</style>
