<template>
  <SignUsersTable />
</template>
<script>
// import UsersTableSuite from "@/components/user/UsersTableSuite";
import SignUsersTable from "@/components/sign/users/SignUsersTable.vue";
export default {
  name: "Users",
  components: { SignUsersTable },
};
</script>
