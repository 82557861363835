<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Editar usuários pelo CSV.</v-toolbar-title>
      </v-toolbar>
      <div class="pa-10 uk-container uk-padding ml-1">
        <div class="uk-timeline">
          <Alert color="info" dense class="mb-6">
            Para entender mais sobre edição de Informações utilizando CSV no
            Conecta Suite,
            <a
              href="https://conectasuite.com/perguntas-frequentes/como-editar-tags-no-csv/"
              >consulte o nosso FAQ.</a
            >
          </Alert>
          <div class="uk-timeline-item">
            <div class="uk-timeline-icon">
              <span class="uk-badge">1</span>
            </div>
            <div class="uk-timeline-content">
              <div class="uk-card uk-card-default uk-margin-medium-bottom">
                <div class="uk-card-header">
                  <div class="uk-grid-small uk-flex-middle">
                    <div class="text-body-1 font-weight-bold">
                      Fazer o download do arquivo CSV
                    </div>
                  </div>
                </div>
                <div class="uk-card-body">
                  <p class="px-0 my-4">
                    <v-btn
                      color="background"
                      elevation="3"
                      class="primary--text mx-0"
                      @click="handleGetUsers"
                      :loading="loadingUsers"
                      :disabled="loadingUsers"
                    >
                      <v-icon left v-text="'mdi-download'" />
                      Baixar informações dos usuários
                    </v-btn>
                    <download-csv
                      :data="users"
                      :labels="userLabels"
                      :fields="userFields"
                      :file-name="`Usuários - ${companyName}`"
                    >
                      <div id="buttondownloadCsv"></div>
                    </download-csv>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-timeline-item mt-6">
            <div class="uk-timeline-icon">
              <span class="uk-badge">2</span>
            </div>
            <div class="uk-timeline-content">
              <div class="uk-card uk-card-default uk-margin-medium-bottom">
                <div class="uk-card-header">
                  <div class="uk-grid-small uk-flex-middle">
                    <div class="text-body-1 font-weight-bold">
                      Adicionar ou editar informações do usuário no modelo CSV.
                    </div>
                    <span class="uk-margin-auto-left text-body-2"
                      >Os campos obrigatórios são os nomes (primeiro e último) e
                      endereço de e-mail.</span
                    >
                  </div>
                </div>
                <div class="uk-card-body pt-4">
                  <v-card class="transparent py-2 elevation-0" tile>
                    <table class="text-body-2">
                      <thead class="grey lighten-2 border-0">
                        <tr>
                          <th class="text-left pa-2">Primeiro nome</th>
                          <th class="text-left pa-2">Último nome</th>
                          <th class="text-left pa-2">E-mail</th>
                          <th class="text-left pa-2">...</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="">
                          <td class="text-left pa-2 outlined">Nome</td>
                          <td class="text-left pa-2 outlined">Sobrenome</td>
                          <td class="text-left pa-2 outlined">
                            usuario@mail.com
                          </td>
                          <td class="text-left pa-2 outlined">...</td>
                        </tr>
                      </tbody>
                    </table>

                    <Alert color="info" dense class="mt-5">
                      As informações definidas como obrigatórias não serão
                      alteradas ao aplicar.
                    </Alert>
                  </v-card>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-timeline-item mt-6">
            <div class="uk-timeline-icon">
              <span class="uk-badge">3</span>
            </div>
            <div class="uk-timeline-content">
              <div>
                <div class="uk-card-header">
                  <div class="uk-grid-small uk-flex-middle">
                    <div class="text-body-1 font-weight-bold">
                      Fazer o upload do arquivo CSV
                    </div>
                  </div>
                </div>
                <div class="uk-card-body pt-4">
                  <v-card flat tile :style="styles">
                    <Alert v-if="hasCsvError" color="error" dense class="mt-2">
                      <span class="font-weight-medium">{{
                        csvUploadError
                      }}</span>
                    </Alert>

                    <Alert
                      v-if="hasManyUsers"
                      color="warning"
                      dense
                      class="mt-2"
                    >
                      <span class="font-weight-medium">
                        Você possui {{ usersNumber }} usuários, a atualização em
                        massa pode levar alguns minutos até serem efetivadas.
                      </span>
                    </Alert>

                    <upload-csv
                      @content="setContent"
                      @error="setError"
                      color="background"
                      elevation="3"
                      custom-class="primary--text my-2"
                      text="Anexar arquivo CSV"
                    />

                    <v-card-subtitle
                      class="px-0 mt-6"
                      v-if="results.length > 0"
                    >
                      Verifique se as informações estão corretas antes de
                      confirmar.
                    </v-card-subtitle>

                    <v-card
                      outlined
                      class="overflow-hidden mt-2"
                      width="100%"
                      v-if="results.length > 0"
                    >
                      <v-card-subtitle
                        class="px-4 py-2 font-weight-medium text-body-2"
                      >
                        Pré-visualização das informações contidas no CSV
                      </v-card-subtitle>

                      <v-divider />

                      <v-data-table
                        :headers="fileHeaders"
                        :items="results"
                        :items-per-page="-1"
                        fixed-header
                        hide-default-footer
                        height="250px"
                        class="elevation-0 overflow-auto"
                        dense
                        calculate-widths
                      >
                        <template v-slot:[`item.sign_photo`]="{ item }">
                          <v-avatar v-if="item.sign_photo" size="24">
                            <img
                              :src="item.sign_photo"
                              :alt="`${item.first_name} ${item.last_name}`"
                            />
                          </v-avatar>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-footer
        rounded
        style="height: 70px"
        color="background"
        class="border-bottom rounded-xl pl-10"
      >
        <div>
          <v-btn
            text
            color="grey"
            :disabled="!results.length || loading"
            @click="resetData"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="accent"
            class="px-4 white--text"
            :loading="loading"
            @click="saveUsers"
            :disabled="!results.length || loading"
          >
            Salvar
          </v-btn>
        </div>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import JsonCSV from "@/components/main/shared/download.vue";
import Upload from "@/components/main/shared/Upload.vue";
import { mapActions, mapGetters } from "vuex";
import {
  userFields,
  userFileHeaders,
  userLabels,
} from "@/helpers/variables/files";

export default {
  name: "UsersTagsCSV",

  props: {
    show: { type: Boolean, required: true },
  },

  components: {
    "download-csv": JsonCSV,
    "upload-csv": Upload,
  },

  data() {
    return {
      userFields,
      userLabels,
      errorMessages,
      loadingUsers: false,
      csvUploadError: false,
      results: [],
      loading: false,
    };
  },

  methods: {
    ...mapActions(["saveUsersTags", "getUsers"]),

    async handleGetUsers() {
      this.loadingUsers = true;
      await this.getUsers();
      document.getElementById("buttondownloadCsv").click();
      this.loadingUsers = false;
    },

    async saveUsers() {
      this.loading = true;
      await this.saveUsersTags(this.results);
      this.loading = false;
      this.resetData();
      this.$emit("updateUsersTableData");
    },

    resetData() {
      this.results = [];
      this.csvUploadError = false;
    },

    setContent(data) {
      this.results = Object.values(data);
      this.csvUploadError = false;
    },

    setError(messageKey) {
      this.results = [];
      this.csvUploadError = errorMessages[messageKey];
    },
  },

  computed: {
    ...mapGetters([
      "company",
      "usersNumber",
      "users",
      "usersCustomTagKeys",
      "usersCustomTags",
    ]),

    companyName() {
      return this.company.name;
    },

    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },

    fileHeaders() {
      const customTagsHeaders = this.usersCustomTagKeys.map((tagKey) => {
        const customTag = this.usersCustomTags[tagKey];
        return {
          text: customTag.name,
          value: `custom_tags.${tagKey}.value`,
          sortable: false,
          width: 150,
        };
      });

      return [...userFileHeaders, ...customTagsHeaders];
    },

    styles() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "max-width: 400px";
      }
      return `max-width: ${
        this.$vuetify.breakpoint.lgAndDown ? "900px" : "1200px"
      }`;
    },

    hasCsvError() {
      return this.csvUploadError || false;
    },

    hasManyUsers() {
      return this.usersNumber.length >= 500;
    },
  },
};
</script>

<style>
.v-tab {
  text-transform: none;
}

.fixed-element {
  position: sticky;
  top: 0;
  z-index: 4;
}

.uk-timeline .uk-timeline-item {
  display: flex;
  position: relative;
}

.uk-timeline .uk-timeline-item::before {
  background: #dadee4;
  content: "";
  height: 100%;
  left: 12px;
  position: absolute;
  top: 24px;
  width: 1px;
}

.uk-timeline .uk-timeline-item:last-of-type::before {
  background: #dadee4;
  content: "";
  height: 100%;
  left: 12px;
  top: 24px;
  width: 0px;
}

.uk-badge {
  background: var(--v-primary-base);
  border-radius: 50%;
  padding: 3px 8px;
  font-size: 0.9rem;
  color: #fff;
  font-weight: bold;
}

.uk-timeline .uk-timeline-item .uk-timeline-icon .uk-badge {
  margin-top: 20px;
  width: 40px;
  height: 40px;
  font-family: "Roboto";
}

.uk-timeline .uk-timeline-item .uk-timeline-content {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 0 0 1rem;
  font-family: "Roboto";
}
</style>
