<template>
  <div>
    <v-card-subtitle class="font-weight-medium pt-0"> Plano </v-card-subtitle>
    <v-card-text>
      <v-autocomplete
        v-model="selectedPlan"
        :items="activePlans"
        :multiple="!singleChoice"
        :hide-selected="singleChoice"
        outlined
        small-chips
        item-text="name"
        item-value="id"
        clearable
        hide-details
      >
        <!-- ITENS SELECIONADOS -->
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <span class="pr-2" v-if="singleChoice">
            {{ item.name }}
          </span>
          <v-chip
            v-else
            v-bind="attrs"
            :color="secondaryColor"
            :input-value="selected"
            label
            small
            dark
            placeholder="Nenhum plano selecionado"
          >
            <span class="pr-2">
              {{ item.name }}
            </span>
            <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
          </v-chip>
        </template>

        <!-- ITENS DISPONÍVEIS PARA SELEÇÃO -->
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item
            v-on="on"
            v-bind="attrs"
            :active-class="lightColors[productName]"
            :class="`mb-1 ${primaryColor}--text text--darken-2`"
            max-width="200"
          >
            <v-list-item-icon v-if="!singleChoice">
              <v-icon
                :color="isSelected(item.key) ? `${primaryColor} darken-2` : ''"
                v-text="
                  isSelected(item.key)
                    ? 'mdi-checkbox-marked'
                    : 'mdi-checkbox-blank-outline'
                "
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.name"
                class="text-left font-weight-medium"
              ></v-list-item-title>
              <v-list-item-subtitle v-if="isConecta" class="text-left">
                <v-chip
                  label
                  x-small
                  class="font-weight-medium secondary--text text--lighten-2"
                >
                  {{ item.sku }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-card-text>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { lightColors } from "@/helpers/variables/colors";

export default {
  name: "PlanPicker",

  props: {
    selected: { type: [Array, String], default: "" },
    returnIds: { type: Boolean, default: false },
    singleChoice: { type: Boolean, default: false },
  },

  data() {
    return {
      selectedPlan: [],
      lightColors,
    };
  },

  computed: {
    ...mapGetters(["secondaryColor", "primaryColor", "plans", "isConecta"]),

    activePlans() {
      if (this.isConecta) {
        return this.plans.filter((plan) => plan.is_active);
      }
      return this.plans.filter((plan) => plan.is_active && plan.is_visible);
    },

    selectedKeys() {
      if (this.singleChoice) {
        return this.selectedPlan;
      }
      return this.selectedPlan.map((item) => item.key);
    },
  },

  watch: {
    selectedPlan() {
      if (this.returnIds) {
        this.$emit("update", this.selectedKeys);
      } else {
        this.$emit("update", this.selectedPlan);
      }
    },

    selected() {
      if (!this.selected) {
        this.selectedPlan = this.selected;
      }
    },
  },

  methods: {
    ...mapActions(["getPlans", "productName"]),

    init() {
      if (
        typeof this.selected !== "string" &&
        this.selected &&
        this.selected.length > 0
      ) {
        this.selectedPlan = this.selected.reduce((acc, item) => {
          const search = this.plans.find((template) => template.key === item);

          if (search) {
            acc.push(search);
          }

          return acc;
        }, []);
      } else {
        this.selectedPlan = this.selected;
      }
    },

    isSelected(id) {
      return this.selectedPlan.find((item) => item.key === id);
    },
  },

  beforeMount() {
    if (!this.plans || (this.plans && this.plans.length === 0)) {
      this.getPlans();
    }

    this.init();
  },
};
</script>
