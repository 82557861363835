<template>
  <v-container class="pa-0 my-0" style="max-width: 1160px">
    <div>
      <v-card-text class="d-flex flex-row align-center px-0 pt-0 mt-5 mb-2">
        <h2 class="font-weight-bold">
          {{ $t("vacationApply.manageAccess.subtitle") }}
        </h2>
        <div class="ms-3">
          <Logo
            noMargins
            full
            width="140"
            height="30"
            product="conecta_control"
          />
        </div>
      </v-card-text>

      <v-card-text class="px-0 pt-0 pb-1 mb-2 text-justify">
        <div
          class="text-body-1 grey--text text--darken-2"
          style="max-width: 800px"
        >
          <p>
            {{ $t("vacationApply.manageAccess.description") }}
          </p>
          <p>{{ $t("vacationApply.manageAccess.question") }}</p>
        </div>
      </v-card-text>
    </div>

    <v-card-title class="pa-0 mt-0">
      {{ $t("common.usersSelecteds") }} ({{ selectedUsersNumber }})
    </v-card-title>

    <Alert
      v-if="!hasSelected"
      :text="false"
      class="mt-4"
      color="error"
      dense
      dark
    >
      {{ $t("common.noUserPleaseSelect") }}
    </Alert>

    <v-card
      class="my-3 rounded"
      flat
      outlined
      style="border-color: rgba(0, 0, 0, 0.42)"
      :loading="loadingSign"
    >
      <div class="pa-2">
        <v-chip
          color="lighten-3"
          pill
          class="ma-1 link"
          v-for="(item, index) in selectedUsersObjects"
          :key="`u${index}`"
        >
          <v-avatar width="30" left>
            <img :src="item.photo || '@/assets/user-default.png'" />
          </v-avatar>
          <span class="px-2">
            {{ item.name }}
          </span>
        </v-chip>
      </div>
    </v-card>

    <v-text-field
      append-icon="mdi-calendar"
      :value="vacationPeriod"
      :label="$t('common.startEndDate')"
      readonly
      :loading="loadingSign"
      hide-details
      outlined
    />

    <InviteToUpsell
      v-if="!hasConectaControl"
      analyticsClick="vacation_manage_access"
      invitationText="vacationApply.manageAccess.upsellInvitation"
      analyticsDescription="Conheça os planos no step de controle de acesso na aplicação de férias no Suite"
    />

    <v-card-actions class="px-0 mt-4">
      <v-spacer />
      <v-btn
        @click.stop="$emit('skip')"
        :disabled="!hasSelected || loadingSign"
        color="accent"
        class="mx-auto mr-5 px-5"
        large
        outlined
      >
        {{ $t("vacationApply.manageAccess.dontBlock") }}
      </v-btn>
      <v-btn
        @click.stop="applyControlVacation"
        :disabled="!hasSelected || !hasConectaControl || loadingSign"
        :loading="loadingSign"
        color="accent"
        class="mx-auto px-5"
        large
      >
        {{ $t("vacationApply.manageAccess.block") }}
      </v-btn>
    </v-card-actions>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import InviteToUpsell from "@/components/purchase/InviteToUpsell.vue";
import { formatDateWithDayOfWeek } from "@/helpers/services/utils";

export default {
  name: "ManageUserAccess",

  components: {
    InviteToUpsell,
  },

  props: {
    selectedUsers: { type: Array, required: true },
    vacationSettings: { type: Object, required: true },
  },

  computed: {
    ...mapGetters(["users", "loadingSign", "token", "hasConectaControl"]),

    selectedUsersNumber() {
      return this.selectedUsers.length;
    },

    hasSelected() {
      return this.selectedUsersNumber > 0;
    },

    vacationPeriod() {
      const { start_time, end_time } = this.vacationSettings;
      if (!start_time || !end_time) {
        return null;
      }
      return [start_time, end_time]
        .map((date) => formatDateWithDayOfWeek(date))
        .join(" ~ ");
    },

    selectedUsersObjects() {
      return this.selectedUsers
        .map((email) => this.users.find((user) => user.email == email))
        .filter((value) => value);
    },
  },

  methods: {
    ...mapMutations(["setSignLoading", "setSnackBar"]),

    async applyControlVacation() {
      this.setSignLoading(true);

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/vacation-access-control`;

      const payload = {
        users: this.selectedUsersObjects.map((user) => user.id_google),
        start_time: this.vacationSettings.start_time,
        end_time: this.vacationSettings.end_time,
      };

      const auth = {
        headers: {
          Authorization: this.token,
        },
      };

      await this.$axios
        .put(url, payload, auth)
        .then(() => {
          this.setSnackBar({
            show: true,
            color: "success",
            message: this.$t("vacationApply.manageAccess.success"),
          });
          this.$emit("skip");
        })
        .catch(() => {
          this.setSnackBar({
            show: true,
            color: "error",
            message: this.$t("vacationApply.manageAccess.failure"),
          });
          this.$emit("reset", true);
        })
        .finally(() => this.setSignLoading(false));
    },
  },
};
</script>
<style>
.selected-border {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 3px !important;
  border-color: var(--v-primary-base) !important;
}
</style>
