var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height d-flex flex-column",staticStyle:{"height":"90vh"},attrs:{"fluid":_vm.$vuetify.breakpoint.lgAndUp}},[_c('v-row',{staticClass:"ma-0 pa-0 mb-6",attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex flex-column align-center pt-0",attrs:{"cols":"12","md":"6","lg":"4","xl":"5","sm":"12"}},[_c('v-img',{attrs:{"width":"500","src":_vm.successImage,"contain":""}})],1),_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","sm":"12","md":"6","lg":"8","xl":"7"}},[_c('v-card-title',{staticClass:"text-h5 font-weight-bold break-word primary--text text--primary-4"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-list',_vm._l((_vm.functionalityHints),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"25","color":"success"},domProps:{"textContent":_vm._s(item.icon ? item.icon : 'ph-fill ph-check-circle')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.title)}}),(item.subtitle)?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.subtitle)}}):_vm._e()],1)],1)}),1),(
          _vm.renewFeedbackOnSecondTime(
            this.currentUser.feedbacks.apply_signature_in_batch
          ) && _vm.checkShowFeedback('apply_signature_in_batch')
        )?_c('InlineFeatureRating',{staticClass:"mx-4 my-4",attrs:{"feature-name":"Aplicar assinatura de e-mail","feature-slug":"apply_signature_in_batch"}}):_vm._e(),_c('v-card-actions',{class:`mx-3 mt-6 ${
          _vm.$vuetify.breakpoint.smAndDown ? 'flex-column' : ''
        }`},[_c('v-btn',{class:`text-none text-body-1 font-weight-medium pr-8 pl-5  ${
            _vm.$vuetify.breakpoint.smAndDown ? 'mb-4' : 'mr-4'
          }`,attrs:{"x-large":"","block":_vm.$vuetify.breakpoint.xs,"color":"primary"},on:{"click":_vm.primaryAction}},[_c('v-icon',{staticClass:"mr-4",attrs:{"left":"","size":"20"},domProps:{"textContent":_vm._s(_vm.primaryActionIcon)}}),_vm._v(" "+_vm._s(_vm.primaryActionText)+" ")],1),_c('v-btn',{class:`text-none text-body-1 font-weight-medium px-6`,attrs:{"x-large":"","block":_vm.$vuetify.breakpoint.xs,"outlined":"","color":"primary"},on:{"click":_vm.secondaryAction}},[_vm._v(" "+_vm._s(_vm.secondaryActionText)+" "),_c('v-icon',{staticClass:"ml-4",attrs:{"right":"","size":"20"},domProps:{"textContent":_vm._s('ph-arrow-right')}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }