import * as moment from "moment";
import { formatDateToUnix } from "@/helpers/services/utils";

export const today = moment().startOf("day");

export const tomorow = moment().add(1, "days").startOf("day");

export const date90DaysAgo = moment().subtract(90, "days");

export const date30DaysAgo = moment().subtract(30, "days");

export const date14DaysAgo = moment().subtract(14, "days");

export const date7DaysAgo = moment().subtract(7, "days");

export const endOfDay = moment().endOf("day");

export const endOfTomorrow = moment().add(1, "days").endOf("day");

export const next7DaysDate = moment().add(6, "days");

export const next14DaysDate = moment().add(14, "days");

export const endTrialPeriodOptions = [
  {
    name: "Últimos 90 dias",
    value: formatDateToUnix(date90DaysAgo) + "," + formatDateToUnix(today),
    show: true,
  },
  {
    name: "Últimos 30 dias",
    value: formatDateToUnix(date30DaysAgo) + "," + formatDateToUnix(today),
    show: true,
  },
  {
    name: "Últimos 14 dias",
    value: formatDateToUnix(date14DaysAgo) + "," + formatDateToUnix(today),
    show: true,
  },
  {
    name: "Últimos 7 dias",
    value: formatDateToUnix(date7DaysAgo) + "," + formatDateToUnix(today),
    show: true,
  },
  {
    name: "Hoje",
    value: formatDateToUnix(today) + "," + formatDateToUnix(endOfDay),
    show: true,
  },
  {
    name: "Amanhã",
    value: formatDateToUnix(tomorow) + "," + formatDateToUnix(endOfTomorrow),
    show: true,
  },
  {
    name: "Próximos 7 dias",
    value: formatDateToUnix(today) + "," + formatDateToUnix(next7DaysDate),
    show: true,
  },
  {
    name: "Próximos 14 dias",
    value: formatDateToUnix(today) + "," + formatDateToUnix(next14DaysDate),
    show: true,
  },
];
