var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ResponsibleMenu',{attrs:{"show-hide":_vm.showHide,"content-class":"pa-0","window-width":"160","button-icon":'mdi-view-column-outline',"button-text":_vm.mobileView ? _vm.$t('common.customizeColumns') : false},on:{"update:show":function($event){_vm.showHide = $event}}},[_c('v-card-title',{staticClass:"text-subtitle-1 font-weight-bold mx-1 mb-0 pb-2"},[_vm._v(" "+_vm._s(_vm.$t("common.customizeColumns"))+" ")]),_c('v-card-subtitle',{staticClass:"caption pt-1 mx-1 mb-0 pb-2"},[_vm._v(" "+_vm._s(_vm.$t("common.customizeColumnsDescription"))+" ")]),_c('v-list',{staticStyle:{"height":"52vh","overflow-y":"scroll"}},[_c('v-divider'),_c('draggable',{attrs:{"list":_vm.headers,"draggable":".move"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}}},_vm._l((_vm.headers),function(item,i){return _c('div',{key:i,class:`${!item.fixed ? 'move' : ''}`},[(
            item.value !== 'data-table-expand' && item.value !== 'actions'
          )?_c('v-list-item',{class:{
            'text-subtitle-2': true,
            'font-weight-regular': true,
            'list-group-item': true,
            'pr-4': true,
            'disabled-item': item.fixed,
            'border-bottom': i < _vm.headers.length - 1,
            'py-1': true,
          },attrs:{"disabled":item.fixed}},[_c('v-list-item-action',{staticClass:"mx-0 px-0"},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[(!item.fixed)?_c('path',{attrs:{"d":"M8.25 13.5C8.25 14.325 7.575 15 6.75 15C5.925 15 5.25 14.325 5.25 13.5C5.25 12.675 5.925 12 6.75 12C7.575 12 8.25 12.675 \n                              8.25 13.5ZM6.75 7.5C5.925 7.5 5.25 8.175 5.25 9C5.25 9.825 5.925 10.5 6.75 10.5C7.575 10.5 8.25 9.825 8.25 9C8.25 8.175 7.575 \n                              7.5 6.75 7.5ZM6.75 3C5.925 3 5.25 3.675 5.25 4.5C5.25 5.325 5.925 6 6.75 6C7.575 6 8.25 5.325 8.25 4.5C8.25 3.675 7.575 3 6.75 \n                              3ZM11.25 6C12.075 6 12.75 5.325 12.75 4.5C12.75 3.675 12.075 3 11.25 3C10.425 3 9.75 3.675 9.75 4.5C9.75 5.325 10.425 6 11.25 \n                              6ZM11.25 7.5C10.425 7.5 9.75 8.175 9.75 9C9.75 9.825 10.425 10.5 11.25 10.5C12.075 10.5 12.75 9.825 12.75 9C12.75 8.175 12.075 \n                              7.5 11.25 7.5ZM11.25 12C10.425 12 9.75 12.675 9.75 13.5C9.75 14.325 10.425 15 11.25 15C12.075 15 12.75 14.325 12.75 13.5C12.75 \n                              12.675 12.075 12 11.25 12Z","fill":"#979797"}}):_vm._e()])]),_c('v-list-item-action',{staticClass:"mx-0 px-0"},[_c('v-checkbox',{attrs:{"disabled":item.fixed || (!item.show && _vm.canNotAddColumn),"color":"primary"},model:{value:(item.show),callback:function ($$v) {_vm.$set(item, "show", $$v)},expression:"item.show"}})],1),_c('v-list-item-content',{class:item.fixed || (!item.show && _vm.canNotAddColumn)
                ? 'grey--text text--darken-1 ml-2'
                : 'ml-2 link font-weight-regular'},[(item.fixed || (!item.show && _vm.canNotAddColumn))?_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}}):_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)},on:{"click":function($event){item.show = !item.show}}})],1),_c('v-list-item-icon',{staticClass:"my-auto mx-0"},[_c('v-tooltip',{attrs:{"bottom":"","nudge-left":"","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(i > 1 && !item.fixed)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.moveUp(i)}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-chevron-up')}})],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.moveColumnUp")))])]),_c('v-tooltip',{attrs:{"bottom":"","nudge-left":"","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(i < _vm.headers.length - 1 && !item.fixed)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.moveDown(i)}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-chevron-down')}})],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.moveColumnDown")))])])],1)],1):_vm._e()],1)}),0)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pb-4 pt-4 px-4 justify-end"},[_c('v-btn',{staticClass:"elevation-0 text-none text-subtitle-1 font-weight-medium",attrs:{"color":"secondary lighten-2","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("action.cancel"))+" ")]),_c('v-btn',{staticClass:"elevation-0 text-none text-subtitle-1 ml-4 px-6 font-weight-medium",attrs:{"color":"accent"},on:{"click":_vm.changeView}},[_vm._v(" "+_vm._s(_vm.$t("action.apply"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }