<template>
  <div>
    <v-card-subtitle class="font-weight-medium pt-0">
      Encerramento do trial (período de teste)
    </v-card-subtitle>
    <v-card-text>
      <v-autocomplete
        v-model="selectedPeriod"
        :items="endTrialPeriodOptions"
        hide-selected
        outlined
        item-text="name"
        item-value="value"
        hide-details
        clearable
      />
    </v-card-text>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { endTrialPeriodOptions } from "@/helpers/variables/dates";

export default {
  name: "EndTrialPicker",

  props: {
    selected: { type: [Array, String], default: "" },
  },

  data() {
    return {
      selectedPeriod: "",
      endTrialPeriodOptions,
    };
  },

  computed: {
    ...mapGetters(["secondaryColor", "primaryColor"]),
  },

  watch: {
    selectedPeriod() {
      this.$emit("update", this.selectedPeriod);
    },

    selected() {
      if (!this.selected) {
        this.selectedPeriod = this.selected;
      }
    },
  },

  methods: {
    init() {
      if (this.selected) {
        this.selectedPeriod = this.selected;
      } else {
        this.selectedPeriod = "";
      }
    },
  },

  beforeMount() {
    this.init();
  },
};
</script>
