<template>
  <div>
    <FullscreenStepsDialog
      :show="show"
      :title="'Criar novo grupo'"
      :steps="stepNames"
      :current-step="currentStep"
      :show-divider="false"
      :custom-change-step-rule="canChangeStep"
      @update:current-step="currentStep = $event"
      @close="closeDialog"
    >
      <template #stepper-content.1>
        <groupsIntro @started="currentStep = 2" />
      </template>
      <template #stepper-content.2>
        <groupsForm
          @back="currentStep = 1"
          @next="currentStep = 3"
          @updateGroupId="updateGroup"
        />
      </template>
      <template #stepper-content.3>
        <groupsSettings
          :group="group"
          :groupTypeSelected="groupTypeSelected"
          :groupConfig="groupConfig"
          @updateGroupConfig="groupConfig = $event"
          @updateGroupType="groupTypeSelected = $event"
          @back="currentStep = 2"
          @next="currentStep = 4"
        />
      </template>
      <template #stepper-content.4>
        <groupsMembers
          :group-members="groupMembers"
          :group="group"
          @back="currentStep = 3"
          @addMember="addMember"
          @updateMember="updateMember"
          @deleteMember="deleteMember"
          @next="currentStep = 5"
        />
      </template>
      <template #stepper-content.5>
        <groupsSharedDrives
          :group="group"
          :groupDrives="groupDrives"
          :loadingScopes="loadingScopes"
          :loadingDrives="loadingDrives"
          :disabled="drivesDisabled"
          @back="currentStep = 4"
          @close="closeDialog()"
          @addGroupDrive="addGroupDrive"
          @updateGroupDrives="updateGroupDrives"
          @deleteGroupDrives="deleteGroupDrives"
        />
      </template>
    </FullscreenStepsDialog>
  </div>
</template>

<script>
import FullscreenStepsDialog from "@/components/main/shared/FullscreenStepsDialog.vue";
import groupsIntro from "./steps/groupsIntro.vue";
import groupsForm from "./steps/groupsForm.vue";
import groupsSettings from "./steps/groupsSettings.vue";
import groupsMembers from "./steps/groupsMembers.vue";
import groupsSharedDrives from "./steps/groupsSharedDrives.vue";
import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CreateGroups",
  components: {
    FullscreenStepsDialog,
    groupsIntro,
    groupsForm,
    groupsSettings,
    groupsMembers,
    groupsSharedDrives,
  },

  props: {
    show: { type: Boolean, default: false },
  },

  data() {
    return {
      currentStep: 1,
      group: {},
      groupTypeSelected: "public",
      groupConfig: {
        whoCanContactOwner: "ANYONE_CAN_CONTACT",
        whoCanViewGroup: "ALL_IN_DOMAIN_CAN_VIEW",
        whoCanPostMessage: "ALL_IN_DOMAIN_CAN_POST",
        whoCanViewMembership: "ALL_IN_DOMAIN_CAN_VIEW",
        whoCanModerateMembers: "OWNERS_AND_MANAGERS",
        whoCanJoin: "ALL_IN_DOMAIN_CAN_JOIN",
        allowExternalMembers: false,
      },
      groupMembers: [],
      loadingScopes: false,
      loadingDrives: false,
      groupDrives: [],
      drivesDisabled: false,
    };
  },

  methods: {
    ...mapActions(["checkScopes", "getCompanySharedDrives"]),

    closeDialog() {
      this.$emit("close");
      this.currentStep = 1;
      this.group = {};
      this.groupMembers = [];
      this.groupDrives = [];
      this.groupTypeSelected = "public";
      this.groupConfig = {
        whoCanContactOwner: "ANYONE_CAN_CONTACT",
        whoCanViewGroup: "ALL_IN_DOMAIN_CAN_VIEW",
        whoCanPostMessage: "ALL_IN_DOMAIN_CAN_POST",
        whoCanViewMembership: "ALL_IN_DOMAIN_CAN_VIEW",
        whoCanModerateMembers: "OWNERS_AND_MANAGERS",
        whoCanJoin: "ALL_IN_DOMAIN_CAN_JOIN",
        allowExternalMembers: false,
      };
    },

    updateGroup(group) {
      this.group = group;
    },

    addMember(user) {
      this.groupMembers.push(user);
    },

    updateMember(user) {
      const memberIndex = this.groupMembers.findIndex(
        (member) => member.email === user.email
      );

      if (memberIndex !== -1) {
        this.groupMembers.splice(memberIndex, 1, user);
      } else {
        console.error(`updateMember(): Group member not found`);
      }
    },

    deleteMember(user) {
      const memberIndex = this.groupMembers.findIndex(
        (member) => member.email === user.email
      );

      if (memberIndex !== -1) {
        this.groupMembers.splice(memberIndex, 1);
      } else {
        console.error(`deleteMember(): Group member not found`);
      }
    },

    addGroupDrive(item) {
      this.groupDrives.push(item);
    },

    updateGroupDrives(item) {
      const driveIndex = this.groupDrives.findIndex(
        (drive) => drive.id === item.id
      );

      if (driveIndex !== -1) {
        this.groupDrives.splice(driveIndex, 1, item);
      } else {
        console.error(`updateGroupDrives(): Group drive not found`);
      }
    },

    deleteGroupDrives(item) {
      const driveIndex = this.groupDrives.findIndex(
        (drive) => drive.id === item.id
      );

      if (driveIndex !== -1) {
        this.groupDrives.splice(driveIndex, 1);
      } else {
        console.error(`deleteGroupDrives(): Group drive not found`);
      }
    },

    canChangeStep(step) {
      return (
        !step.disabled &&
        (step.id == 2 || step.id == 1 || Object.keys(this.group).length !== 0)
      );
    },
  },

  computed: {
    ...mapGetters(["companySharedDrives", "scopeCode"]),

    stepNames() {
      return [
        {
          titleKey: "common.intro",
          subtitleKey: "groupCreation.intro.subtitle",
          disabled: false,
          id: 1,
        },
        {
          titleKey: "groupCreation.createGroup.title",
          subtitleKey: "groupCreation.createGroup.subtitle",
          disabled: false,
          id: 2,
        },
        {
          titleKey: "groupCreation.configureAccess.title",
          subtitleKey: "groupCreation.configureAccess.subtitle",
          disabled: false,
          id: 3,
        },
        {
          titleKey: "groupCreation.addParticipants.title",
          subtitleKey: "groupCreation.addParticipants.subtitle",
          disabled: false,
          id: 4,
        },
        {
          titleKey: "groupCreation.configureSharedDrives.title",
          subtitleKey: "groupCreation.configureSharedDrives.subtitle",
          disabled: false,
          id: 5,
        },
      ];
    },
  },

  async mounted() {
    const driveScope = INCREMENTAL_SCOPES.ADMIN_DRIVE;
    if (!this.scopeCode(driveScope)) {
      this.loadingScopes = true;
      const payload = {
        scopes: [driveScope],
      };

      await this.checkScopes(payload)
        .then(() => {
          this.drivesDisabled = false;
        })
        .catch(() => {
          this.drivesDisabled = true;
        })
        .finally(() => {
          this.loadingScopes = false;
        });
    }
    if (!this.companySharedDrives.length) {
      this.loadingDrives = true;
      await this.getCompanySharedDrives();
      this.loadingDrives = false;
    }
  },
};
</script>

<style>
v-toolbar__extension {
  padding: 0px !important;
}

.v-stepper__step__step,
.v-stepper__step__step.primary {
  background-color: var(--v-accent-base) !important;
  border-color: var(--v-accent-base) !important;
}

.disabled > .v-stepper__step__step {
  background-color: rgb(199, 199, 199) !important;
  border-color: grey !important;
}
</style>
