var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showHideDialog)?_c('HeaderDialog',{attrs:{"id":"apply_sendas_batch","color":"primary","title":_vm.$t('common.applySecondaryEmail'),"show":_vm.showHideDialog,"persistent":false,"loading":_vm.loadingSign || _vm.loadingSendAs || _vm.loadingEmails || _vm.loading,"width":"700","disabledAction":_vm.sendAsEmailIsValid ||
    !_vm.sendAsEmail.length ||
    _vm.loadingSign ||
    _vm.loadingSendAs ||
    _vm.loading,"closeText":_vm.$t('action.cancel'),"button-class":"text-none text-subtitle-1 font-weight-medium elevation-0"},on:{"close":function($event){_vm.showHideDialog = false},"action":_vm.selectSendAsMethod},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',{staticClass:"pt-4 pb-2"},[_vm._v(" "+_vm._s(_vm.$t("common.secondaryEmailAddressDescription"))+" ")]),_c('v-combobox',{staticClass:"py-0 mt-2",attrs:{"rules":[(v) => _vm.validateEmail(v, _vm.$t('textFieldRules.invalidEmail'))],"color":"primary","label":"E-mail","hint":`${_vm.$t(
        'common.addAlternativeEmailDescription'
      )} Ex.: comercial@, contato@, financeiro@…`,"clearable":"","open-on-clear":"","items":_vm.emails,"dense":"","loading":_vm.loadingEmails,"disabled":_vm.loadingSign || _vm.loadingSendAs || _vm.loading},model:{value:(_vm.setSendAsEmail),callback:function ($$v) {_vm.setSendAsEmail=$$v},expression:"setSendAsEmail"}}),_c('v-text-field',{staticClass:"py-0 mt-6",attrs:{"label":`${_vm.$t('common.name')} (${_vm.$t('common.optional')})`,"color":"primary","disabled":_vm.loadingSign || _vm.loadingSendAs || _vm.loading},model:{value:(_vm.sendAsName),callback:function ($$v) {_vm.sendAsName=$$v},expression:"sendAsName"}})]},proxy:true}],null,false,510261763)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }