<template>
  <v-container style="max-width: 1160px" class="pb-0">
    <div class="d-flex align-center">
      <h1 class="font-weight-bold" style="font-size: 22px">
        Informações do grupo
      </h1>
      <small class="ml-2">* Indica um campo obrigatório</small>
    </div>
    <div class="mt-3" style="font-size: 14px">
      <p>
        Grupos são comumente formados para equipes de departamentos específicos,
        como por exemplo: tecnologia, suporte e comercial, podendo ser estendido
        também a atividades de marketing, ocasiões especiais e até divisões
        internas dentro de sua empresa.
      </p>
      <v-alert style="font-size: 14px" text type="info"
        ><strong>Recomenda-se</strong> a utilização de grupos para os
        departamentos de sua empresa.</v-alert
      >
    </div>
    <v-form v-model="isValid">
      <div>
        <v-text-field
          v-model="groupName"
          label="Nome*"
          placeholder="Tecnologia"
          :rules="[rules.required]"
          outlined
        ></v-text-field>
        <div class="d-flex align-center justify-center">
          <v-col class="pa-0">
            <v-text-field
              v-model="groupPrimaryEmail"
              label="E-mail principal*"
              placeholder="Tecnologia"
              :rules="[rules.required, rules.primaryEmail]"
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="pa-0">
            <v-select
              class="ml-2"
              v-model="groupDomain"
              :items="domains"
              :label="`Dominio*`"
              :disabled="loading"
              prepend-icon="mdi-at"
              :rules="[rules.required]"
              outlined
            />
          </v-col>
        </div>
        <v-textarea
          v-model="groupDescription"
          outlined
          name="description"
          label="Descrição*"
          :rules="[rules.required]"
          placeholder="Grupo para membros do departamento de tecnologia"
        ></v-textarea>
      </div>
      <div class="mt-5 d-flex justify-space-between">
        <v-btn
          height="52"
          large
          text
          class="text-none text-body-1 font-weight-bold px-8"
          @click="$emit('back')"
        >
          Voltar
        </v-btn>

        <v-btn
          height="52"
          large
          color="accent"
          class="text-none text-body-1 font-weight-bold px-8"
          :disabled="!isValid || loading"
          @click="createGroup()"
          :loading="loading"
        >
          Criar grupo e avançar
          <v-icon size="24" right dark>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isValid: true,
      message: "Opa",
      groupName: "",
      groupPrimaryEmail: "",
      groupDomain: "",
      groupDescription: "",
    };
  },

  computed: {
    ...mapGetters(["domains", "loading"]),

    rules() {
      return {
        required: (v) => !!v || this.$t("textFieldRules.requiredField"),
        primaryEmail: (v) =>
          /^[a-zA-Z0-9._-]+$/.test(v) || "Por favor digite um valor válido",
      };
    },
  },

  methods: {
    ...mapActions(["createGoogleWorkspaceGroup"]),

    async createGroup() {
      let group_settings = {
        name: this.groupName,
        email: this.groupPrimaryEmail + "@" + this.groupDomain,
        description: this.groupDescription,
      };
      let group = await this.createGoogleWorkspaceGroup(group_settings);
      if (group && Object.keys(group).length > 0) {
        this.$emit("next");
        this.$emit("updateGroupId", group);
      }
    },
  },
};
</script>
