export const userLabels = {
  first_name: "First Name [Required]",
  last_name: "Last Name [Required]",
  email: "Email Address [Required]",
  phone: "Work Phone",
  mobile: "Mobile Phone",
  ramal: "Ramal",
  whatsapp_number: "WhatsApp Number",
  departament: "Department",
  position: "Employee Title",
  calendar_schedules_link: "URL for scheduling",
  sign_photo: "Signature photo url URL",
  user_custom_1: "Custom tag 1",
  user_custom_2: "Custom tag 2",
};
export const userFields = [
  "first_name",
  "last_name",
  "email",
  "ramal",
  "whatsapp_number",
  "phone",
  "mobile",
  "departament",
  "position",
  "calendar_schedules_link",
  "sign_photo",
  "user_custom_1",
  "user_custom_2",
  "custom_tags",
];

export const userKeys = {
  "First Name [Required]": "first_name",
  "Last Name [Required]": "last_name",
  "Email Address [Required]": "email",
  Ramal: "ramal",
  "WhatsApp Number": "whatsapp_number",
  "Work Phone": "phone",
  "Mobile Phone": "mobile",
  Department: "departament",
  "Employee Title": "position",
  "URL for scheduling": "calendar_schedules_link",
  "Signature photo url URL": "sign_photo",
  "Custom tag 1": "user_custom_1",
  "Custom tag 2": "user_custom_2",
};

export const userFileHeaders = [
  {
    text: "First Name [Required]",
    value: "first_name",
    sortable: false,
    width: 200,
  },
  {
    text: "Last Name [Required]",
    value: "last_name",
    sortable: false,
    width: 200,
  },
  {
    text: "Email Address [Required]",
    value: "email",
    sortable: false,
  },
  {
    text: "Ramal",
    value: "ramal",
    sortable: false,
    width: 200,
  },
  {
    text: "WhatsApp Number",
    value: "whatsapp_number",
    sortable: false,
    width: 200,
  },
  {
    text: "Work Phone",
    value: "phone",
    sortable: false,
    width: 200,
  },
  {
    text: "Mobile Phone",
    value: "mobile",
    sortable: false,
    width: 200,
  },
  {
    text: "Department",
    value: "departament",
    sortable: false,
    width: 200,
  },
  {
    text: "Employee Title",
    value: "position",
    sortable: false,
    width: 200,
  },
  {
    text: "Signature photo URL",
    value: "sign_photo",
    sortable: false,
    width: 200,
  },
  {
    text: "URL for scheduling",
    value: "calendar_schedules_link",
    sortable: false,
    width: 200,
  },
  {
    text: "Custom tag 1",
    value: "user_custom_1",
    sortable: false,
    width: 200,
  },
  {
    text: "Custom tag 2",
    value: "user_custom_2",
    sortable: false,
    width: 150,
  },
];
