<template>
  <v-container
    style="height: 100%"
    class="d-flex justify-center align-center flex-column"
  >
    <v-row no-gutters>
      <v-col class="d-flex justify-end align-center">
        <v-img :src="groupsImage" width="100%" max-width="510px" />
      </v-col>
      <v-col class="d-flex justify-start align-center">
        <div class="ma-10" style="line-height: 1.25; max-width: 600px">
          <h1 class="line">
            {{ $t("vacationApply.startedVacationFlow.title") }}
          </h1>
          <h3 class="my-3 font-weight-regular">
            {{ $t("vacationApply.startedVacationFlow.subtitle") }}
          </h3>
          <div class="py-5">
            <p>
              <v-icon size="26" color="success">mdi-check-circle</v-icon>
              {{
                $t("vacationApply.startedVacationFlow.setUpAVacationResponder")
              }}
            </p>
            <p>
              <v-icon size="26" color="success">mdi-check-circle</v-icon>
              {{ $t("vacationApply.startedVacationFlow.forwardingReceived") }}
            </p>
            <p>
              <v-icon size="26" color="success">mdi-check-circle</v-icon>
              {{ $t("vacationApply.startedVacationFlow.addHoliday") }}
            </p>
            <p>
              <v-icon size="26" color="success">mdi-check-circle</v-icon>
              {{ $t("vacationApply.startedVacationFlow.autoReply") }}
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-btn
      height="58"
      class="mt-16 text-none text-h6 font-weight-bold px-8"
      large
      color="accent"
      @click="$emit('started')"
      >{{ $t("action.start") }}
      <v-icon size="24" right dark>mdi-arrow-right</v-icon></v-btn
    >
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      groupsImage: require("@/assets/images/vacation_flow.png"),
    };
  },
};
</script>
