<template>
  <v-container style="max-width: 1160px" class="pb-0">
    <div class="d-flex align-center justify-space-between">
      <div>
        <h1 class="font-weight-bold" style="font-size: 22px">
          Adicionar grupo em drives compartilhados
        </h1>
        <p class="mt-3 mr-10 mb-0" style="font-size: 14px">
          Adicione o grupo em drives compartilhados para que todos os
          participantes possam acessar informações da empresa ou do setor ao
          qual está vinculado.
        </p>
      </div>
      <v-btn
        :loading="loading || loadingScopes"
        :disabled="loading || loadingScopes"
        height="44"
        large
        color="primary"
        class="text-none text-body-1 font-weight-regular px-4"
        @click="showDialogAddDrive = true"
        >Adicionar drive
        <v-icon size="24" right dark>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-alert dense style="font-size: 14px" text type="info"
      >É <strong>recomendável</strong> que você adicione grupos aos drives
      compartilhados, em vez de usuários individualmente. Ao adicionar grupos,
      você otimiza a gestão e o compartilhamento de recursos de forma mais
      eficiente e organizada.</v-alert
    >
    <v-card elevation="0" class="card mt-6">
      <v-data-table
        :headers="headers"
        :items="groupDrives"
        outline
        height="420px"
        fixed-header
      >
        <template v-slot:[`item.name`]="{ item }">
          <div class="py-3 d-flex align-center">
            <v-avatar color="grey lighten-2" left size="40">
              <v-icon v-text="'mdi-google-drive'" />
            </v-avatar>
            <div class="ml-3" style="font-size: 14px">
              <p class="font-weight-bold ma-0">
                {{ item.name }}
              </p>
            </div>
          </div>
        </template>
        <template v-slot:[`item.role`]="{ item }" style="max-width: 300px">
          <div class="d-flex align-center">
            <v-select
              :loading="loading"
              :disabled="loading"
              v-model="item.role"
              :items="rolesOptions"
              item-text="value"
              item-value="key"
              style="max-width: 250px; font-size: 14px"
              @change="updateDriveRole(item)"
            />
            <v-btn
              :loading="loading"
              :disabled="loading"
              @click="deletePermission(item)"
              class="ml-6 delete-icon"
              icon
              ><v-icon>mdi-trash-can</v-icon></v-btn
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
    <div class="mt-5 d-flex justify-space-between">
      <v-btn
        height="52"
        large
        text
        class="text-none text-body-1 font-weight-bold px-8"
        @click="$emit('back')"
      >
        Voltar
      </v-btn>

      <v-btn
        height="52"
        large
        color="accent"
        class="text-none text-body-1 font-weight-bold px-8"
        :disabled="loading"
        @click="$emit('close')"
        :loading="loading"
      >
        Finalizar
        <v-icon size="24" right dark>mdi-arrow-right</v-icon>
      </v-btn>
    </div>

    <HeaderDialog
      id="drive_to_user"
      :title="$t('common.addSharedDrive')"
      :show="showDialogAddDrive"
      width="600"
      :loading="loading || loadingDrives || loadingScopes"
      :disabled-action="!!!selectedDrive.id || !role"
      @action="addUserToSharedDrive()"
      @close="reset()"
      :persistent="false"
    >
      <template v-slot:body>
        <p class="pt-4 pb-0 mb-0 mr-2">
          {{ $t("common.addSharedDriveDescription") }}:
        </p>

        <v-autocomplete
          v-model="selectedDrive"
          :items="drivesUserAreNotIn"
          item-text="name"
          :placeholder="$t('common.companyDrive')"
          class="mt-0"
          return-object
        ></v-autocomplete>
        <p class="pb-0 mb-0 pt-3">{{ $t("common.defineTheRole") }}:</p>

        <v-autocomplete
          v-model="role"
          :items="rolesOptions"
          item-text="value"
          item-value="key"
          :placeholder="$t('userInformations.office')"
          class="mt-0"
        ></v-autocomplete>
      </template>
    </HeaderDialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";

export default {
  props: {
    group: { type: Object, default: () => {} },
    groupDrives: { type: Array, default: () => [] },
    loadingScopes: { type: Boolean, default: false },
    loadingDrives: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      showDialogAddDrive: false,
      selectedDrive: {},
      role: "",
    };
  },

  computed: {
    ...mapGetters(["loading", "companySharedDrives", "mainDomain"]),

    drivesUserAreNotIn() {
      return this.companySharedDrives.filter(
        ({ id }) => !this.groupDrives.find((item) => item.id === id)
      );
    },

    headers() {
      return [
        {
          text: "Nome do drive",
          sortable: false,
          value: "name",
        },
        {
          text: "Cargo do grupo",
          width: "270px",
          sortable: false,
          value: "role",
        },
      ];
    },

    rolesOptions() {
      return [
        { key: "organizer", value: "Organizador" },
        { key: "fileOrganizer", value: "Organizador de arquivos" },
        { key: "writer", value: "Escritor" },
        { key: "commenter", value: "Comentador" },
        { key: "reader", value: "Leitor" },
      ];
    },
  },

  methods: {
    ...mapActions([
      "addSendAs",
      "insertGroupOnSharedDrives",
      "deletePermissionOnSharedDrives",
    ]),
    ...mapMutations(["setSnackBar", "setLoading"]),

    deletePermission(item) {
      this.setLoading(true);

      let payload = {
        permission_id: item.permission_id,
        drive_id: item.id,
      };

      this.deletePermissionOnSharedDrives(payload)
        .then(() => {
          this.$emit("deleteGroupDrives", item);
          this.setSnackBar({
            show: true,
            message: successMessages.apply_24hours,
          });
        })
        .catch((error) => {
          console.error("deleteDriveRole()", error);
          const statusCode = error.response?.status;
          const code = error.response?.data?.code;
          const { unknown, SHARED_DRIVE_FORBIDDEN } = errorMessages;
          if (statusCode === 403) {
            this.setSnackBar({
              show: true,
              message: SHARED_DRIVE_FORBIDDEN,
              color: "error",
            });
          } else if (
            code !== "INVALID_GOOGLE_ACCESS_TOKEN" ||
            statusCode !== 401
          ) {
            this.setSnackBar({
              show: true,
              message: unknown,
              color: "error",
            });
          }
        })
        .finally(() => {
          this.reset();
          this.setLoading(false);
        });
    },

    updateDriveRole(item) {
      this.setLoading(true);

      const data = {
        payload: {
          email: this.group.email,
          drive_id: item.id,
          role: item.role,
          type: "group",
        },
      };

      this.insertGroupOnSharedDrives(data)
        .then(() => {
          this.$emit("updateGroupDrives", item);
          this.setSnackBar({
            show: true,
            message: successMessages.apply_24hours,
          });
        })
        .catch((error) => {
          console.error("updateDriveRole()", error);
          const statusCode = error.response?.status;
          const code = error.response?.data?.code;
          const { unknown, SHARED_DRIVE_FORBIDDEN } = errorMessages;
          if (statusCode === 403) {
            this.setSnackBar({
              show: true,
              message: SHARED_DRIVE_FORBIDDEN,
              color: "error",
            });
          } else if (
            code !== "INVALID_GOOGLE_ACCESS_TOKEN" ||
            statusCode !== 401
          ) {
            this.setSnackBar({
              show: true,
              message: unknown,
              color: "error",
            });
          }
        })
        .finally(() => {
          this.reset();
        });
    },

    reset() {
      this.showDialogAddDrive = false;
      this.selectedDrive = {};
      this.setLoading(false);
      this.role = "";
    },

    addUserToSharedDrive() {
      this.setLoading(true);

      const data = {
        payload: {
          email: this.group.email,
          drive_id: this.selectedDrive.id,
          role: this.role,
          type: "group",
        },
      };

      this.insertGroupOnSharedDrives(data)
        .then(({ data }) => {
          this.selectedDrive.permission_id = data.permission_data.id;
          this.selectedDrive.role = this.role;
          this.$emit("addGroupDrive", this.selectedDrive);
          this.setSnackBar({
            show: true,
            message: successMessages.apply_24hours,
          });
        })
        .catch((error) => {
          console.error("insertGroupOnSharedDrives()", error);
          const statusCode = error.response?.status;
          const code = error.response?.data?.code;
          const { unknown, SHARED_DRIVE_FORBIDDEN } = errorMessages;
          if (statusCode === 403) {
            this.setSnackBar({
              show: true,
              message: SHARED_DRIVE_FORBIDDEN,
              color: "error",
            });
          } else if (
            code !== "INVALID_GOOGLE_ACCESS_TOKEN" ||
            statusCode !== 401
          ) {
            this.setSnackBar({
              show: true,
              message: unknown,
              color: "error",
            });
          }
        })
        .finally(() => {
          this.reset();
        });
    },
  },
};
</script>

<style scoped>
.delete-icon {
  color: grey;
}

.delete-icon:hover {
  color: red;
}
</style>
