<template>
  <v-select
    v-model="showPrimaryEmails"
    :items="emailsTypes"
    :background-color="$vuetify.theme.dark ? '' : 'primary'"
    label="Filtrando por"
    :value="$t('usersPage.primaryEmails')"
    :solo="!$vuetify.theme.dark"
    :solo-inverted="$vuetify.theme.dark"
    :disabled="disabled"
    item-text="name"
    item-key="key"
    append-icon="mdi-unfold-more-horizontal"
    flat
    hide-details
    heigth="20"
    dark
    :class="$vuetify.breakpoint.xs ? '' : 'mx-2'"
    :style="`${
      $vuetify.breakpoint.mdAndDown ? 'width: 100%' : 'max-width: 200px'
    }`"
  >
    <template v-slot:selection="{ item }">
      <v-list-item class="px-0 white--text" dense :disabled="disabled">
        <v-list-item-action class="px-0 mr-3 ml-0">
          <v-icon color="white" v-text="'mdi-email-outline'" />
        </v-list-item-action>
        <v-list-item-content class="py-0">
          <v-list-item-subtitle class="caption font-weight-regular white--text">
            Visualizando
          </v-list-item-subtitle>
          <v-list-item-title class="text-subtitle-2">{{
            item.name
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "EmailsTypesSelector",

  props: {
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      showPrimaryEmails: 0,
      emailsTypes: [
        {
          name: this.$t("usersPage.primaryEmails"),
          value: 0,
        },
        {
          name: this.$t("usersPage.allEmails"),
          value: 1,
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["displayOnlyPrimaryEmails"]),
  },

  watch: {
    showPrimaryEmails() {
      this.$emit("update", this.showPrimaryEmails);
    },

    displayOnlyPrimaryEmails() {
      if (this.displayOnlyPrimaryEmails) {
        this.showPrimaryEmails = 0;
      } else {
        this.showPrimaryEmails = 1;
      }
    },
  },
};
</script>
